import { customElement, property } from "lit/decorators.js";
import { html, css, LitElement,unsafeCSS } from "lit";
import Highcharts from 'highcharts/highcharts';
import STRINGS from '~/lang/strings_el.json';
import Utils from '~/utils.js';
import fontAwesome from '~/modules/fontawesome/css/fontawesome.js';
import style from '~/modules/css.js';
import * as XLSX from "xlsx";

@customElement("funds-rings")
export class FundsRings extends LitElement {
    static get properties() {
        return {
            classes: { type: Object },
            data: {type: Array, attribute: true, reflect: true },
            tableConf: {type: Array}
        }
    }

    static styles = [fontAwesome,style]
    constructor(){
        super();
        this.classes = { wrapper: true, switchedView: false };
    }

    firstUpdated(changedProperties) {
        this.fetchData();
        this.data=[];
        this.tableConf = [
            {property: 'Titlos', header: 'XX'},
            {property: 'Sinolo', header: STRINGS.CHARTS.FUNDS.AMOUNT_TOTAL}
        ]
    }

    fetchData() {
        fetch('https://2027.anaptyxi.gov.gr/GetData.ashx?chart=funds')
            .then(response => {
                return response.json();
            })
            .then(data => {
                if (data.errMsg) {
                    throw new Error(STRINGS.ERRORS.NETWORK_ERROR);
                }else{
                    this.data = data;
                    this._enableChart();
                    this.initGrid();
                }
            })
            .catch((error) => {
                console.error('Error:', error);
            });
    }
  
    render() {
        return Utils.getChartContainer.call(this,this.data,STRINGS.CHARTS.FUNDS.TITLE);
    }  
    
    initGrid(){
        let grid = this.shadowRoot.querySelector("#grid");
        grid.options={
            data: this.data,
            info: false,
            paging: false,
            searching: false,
            columns: [
                {data: 'Titlos', title: STRINGS.CHARTS.FUNDS.XCOLUMN},
                {data: 'Sinolo', width: Utils.amountColWidth, title: STRINGS.CHARTS.FUNDS.AMOUNT_TOTAL, className: 'dt-body-right',type: 'num-fmt', render: function (data, type, row) {
                    return type==="sort"?data:data.toLocaleString('el-GR')+" €";
                }
            }
            ]
        };
    }
    
    _switchView(){
        this.classes.switchedView=!this.classes.switchedView;
        this.shadowRoot.querySelector(".wrapper").classList.toggle("switchedView");
    }

    _download(){
        let filename='Export.xlsx';
        let exportData = this.data.map(a=>{
            let item={};
            item[STRINGS.CHARTS.FUNDS.XCOLUMN]=a.Titlos;
            item[STRINGS.CHARTS.FUNDS.AMOUNT_TOTAL]=a.Sinolo;
            return item;
        });
        var ws = XLSX.utils.json_to_sheet(exportData);
        var wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, "Data");
        XLSX.writeFile(wb,filename);

    }

    _enableChart() {
        let sum = this.data.reduce((a,currentValue)=>{return a+currentValue.Sinolo;},0);
        let chartData = this.data.map((a)=>{
            return {
                name: a.Titlos,
                y: a.Sinolo,
                color: Utils.getFundsColors(a["ID"]),
                sliced: true
            }
        });
        chartData=chartData.sort((a,b)=>b.y-a.y); 
        Highcharts.chart(
            this.shadowRoot.querySelector('#container'), {
                chart: {
                    
                },
                //colors: Utils.getHighchartColors(),
                title: {
                    text: undefined
                },
                credits: {
                    enabled: false
                },
                xAxis: {
            
                },
                series: [{
                    data: chartData,
                    dataLabels: {
                        formatter: function () {
                            let x = Utils.round(this.y*100/sum,2);
                            return this.point.name+': '+x+'%';
                        }
                    },
                    type: 'pie',
                    name: STRINGS.CHARTS.FUNDS.AMOUNT_TOTAL,
                        states: {
                            inactive: {
                                opacity: 1
                            }
                        },
                        tooltip: {
                            headerFormat: "<div class='hTooltipHeader'>{point.key}</div><br><br>",
                            pointFormatter: function() {
                                let x = Utils.round(this.y,2);
                                return "<div class=hTooltipBody'>"+STRINGS.CHARTS.FUNDS.AMOUNT_TOTAL+': <b>'+this.y.toLocaleString('el-GR')+" €</b></div>";
                            },
                            useHTML: true
                        },
                        borderRadius: 5
                }
                ],
                plotOptions: {
                    series: {
                        colorByPoint: true
                    }
                }
            }
        );
      }
}